<template>
  <div class="home">
    <!-- 轮播图 -->
    <div style="width: 1300px;margin: 0 auto;padding-top: 20px;" class="lbstyle">
      <Carousel :bannerList="bannerList"></Carousel> 
    </div>
       
    <div class="ManyInfo w">
        <!-- 标题 -->
        <JfTitle title="热点咨询" path="/person"></JfTitle>
        <!-- 内容盒子 -->
        <ContentBox :everyList="informatArr" :showAll="false" :dataCount="3" title="热点资讯"></ContentBox>

        <!-- 标题 -->
        <JfTitle title="租赁设备" path="/lease"></JfTitle>
        <!-- 内容盒子 -->
        <LeaseContentBox :everyList="InfoLists" :showAll="false" :dataCount="3"  title="租赁设备"></LeaseContentBox>

        <!-- 图片标题  -->
        <IGtitle></IGtitle>
        <!-- 图片内容 -->
        <IGcontent :everyList="teamInfoArr" :showAll="false" :dataCount="5"></IGcontent>

    </div>
  </div>
</template>

<script>
import Carousel from "../components/Carousel"
import ContentText from "../components/ContentText"
import JfTitle from "../components/home/JfTitle"
import ContentBox from "../components/home/ContentBox"
import LeaseContentBox from "../components/home/LeaseContentBox"
import IGtitle from "../components/home/IGtitle"
import IGcontent from "../components/home/IGcontent"
import { getHotArticles } from "@/api/informat"
import { devicesTypeInfo } from "@/api/lease"
import { postTeamPages } from "@/api/team"
export default {
  name: "Home",
  components:{
    Carousel,
    JfTitle,
    ContentBox,
    ContentText,
    IGtitle,
    IGcontent,
    LeaseContentBox
  },
  props: {},
  data() {
    return {
      teamInfoArr:[], //团队
      InfoLists:[], //设备
      informatArr:[],  //后台返回的信息列表
      currentPage4: 1, // 当前页数
      pageSize: 5, // 每页显示数量
      totalItems: 0, // 总数据条数
      bannerList: [
        {url:require('@/assets/img/lunbo/lb3.jpg'),link:'https://blog.csdn.net/csdnnews/article/details/136828721?spm=1000.2115.3001.5926',title:'史上最大开源LLM，参数高达3140亿！马斯克如约开源Grok，10小时狂揽10000颗Star'},
        {url:require('@/assets/img/lunbo/lb2.png'),link:'https://blog.csdn.net/OneFlow_Official/article/details/136668732',title:'好久不见！OneFlow 1.0全新版本上线'},
        {url:require('@/assets/img/lunbo/lb4.png'),link:'https://blog.csdn.net/qq_45156060/article/details/136829014',title:'马斯克旗下xAI正式宣布开源大模型Grok-1'},
        {url:require('@/assets/img/lunbo/lb5.jpg'),link:'https://finance.sina.com.cn/roll/2024-03-18/doc-inansine6100238.shtml?cre=tianyi&mod=pcpager_fintoutiao&loc=19&r=0&rfunc=62&tj=cxvertical_pc_pager_spt&tr=174',title:'全球科技股浪潮奔涌而来 机构关注AI投资主题'},
      ],
    };
  },
  created() {
    this.getInformatList()
    this.showLists()
    this.TeadPagesInfo()
  },
  mounted() {},
  methods: {
      //获取资讯列表信息
    async getInformatList(){
      const { data } = await getHotArticles()
      this.informatArr = data
      console.log(data,898998);
    },
    //获得租赁列表
    async showLists(){
          const {data} = await devicesTypeInfo({
              pageNo:this.currentPage4,
              pageSize:this.pageSize,
          })
          // console.log(data,8881);
          this.InfoLists = data.list
      },
    //获得团队
      async TeadPagesInfo(){
      const { data } = await postTeamPages({
        pageNo: this.currentPage4,
        pageSize: this.pageSize
      })
      
      this.teamInfoArr = data.list
      // console.log(this.teamInfoArr,8882);
    },
  },

};
</script>

<style lang="scss" scoped>
  .home{
    background-color: #ebf0fa;
    .ManyInfo{
      padding-bottom: 50px;
    }
  }
</style>