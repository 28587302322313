import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/informat',
    component: () => import(/* webpackChunkName: "informat" */ '../views/Informat.vue')
  },
  {
    path: '/lease',
    component: () => import(/* webpackChunkName: "lease" */ '../views/Lease.vue')
  },
  {
    path: '/myMoney',
    component: () => import(/* webpackChunkName: "myMoney" */ '../views/myMoney.vue'),
    redirect: '/myMoney/index', // 添加重定向属性
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "index" */ '../views/uesrMoney/index.vue'),
      },
      {
        path: 'balance',
        component: () => import(/* webpackChunkName: "balance" */ '../views/uesrMoney/balance.vue'),
      },
      {
        path: 'recharge',
        component: () => import(/* webpackChunkName: "recharge" */ '../views/uesrMoney/recharge.vue'),
      },
      {
        path: 'income',
        component: () => import(/* webpackChunkName: "income" */ '../views/uesrMoney/income.vue'),
      }
    ]
  },
  {
    path: '/person',
    component: () => import(/* webpackChunkName: "person" */ '../views/Person.vue')
  },
  {
    path: '/team',
    component: () => import(/* webpackChunkName: "team" */ '../views/Team.vue')
  },
  {
    path: '/searchIndex',
    component: () => import(/* webpackChunkName: "searchIndex" */ '../views/search/searchIndex.vue'),
    children: [
      {
        path: '', // 默认子路由
        redirect: 'personitem'
      },
      {
        path: 'personitem',
        component: () => import(/* webpackChunkName: "personitem" */ '../views/search/components/personitem.vue')
      },
      {
        path: 'teamitem',
        component: () => import(/* webpackChunkName: "teamitem" */ '../views/search/components/teamitem.vue')
      },
      {
        path: 'leaseitem',
        component: () => import(/* webpackChunkName: "leaseitem" */ '../views/search/components/leaseitem.vue')
      },
      {
        path: 'user',
        component: () => import(/* webpackChunkName: "user" */ '../views/search/components/user.vue')
      },
    ]
  },
  {
    path: '/txtdetail',
    component: () => import(/* webpackChunkName: "txtdetail" */ '../views/TxtDetail.vue')
  },
  {
    path: '/arPublish',
    component: () => import(/* webpackChunkName: "arPublish" */ '../views/personInfo/ArPublish.vue'),
  },
  {
    path: '/ZlinPublish',
    component: () => import(/* webpackChunkName: "ZlinPublish" */ '../views/personInfo/ZlinPublish.vue')
  },
  {
    path: '/CooperatPublic',
    component: () => import(/* webpackChunkName: "CooperatPublic" */ '../views/personInfo/cooperatPublic.vue')
  },
  {
    path: '/TeamPublish',
    component: () => import(/* webpackChunkName: "TeamPublish" */ '../views/personInfo/TeamPublish.vue')
  },
  {
    path: '/PersonPublish',
    component: () => import(/* webpackChunkName: "PersonPublish" */ '../views/personInfo/PersonPublish.vue')
  },
  {
    path: '/cooperatDetail',
    component: () => import(/* webpackChunkName: "cooperatDetail" */ '../views/cooperat/cooperatDetail.vue')
  },
  {
    path: '/personCt',
    component: () => import(/* webpackChunkName: "personCt" */ '../views/personInfo/PersonCt.vue'),
    // redirect: '/personCt/personalData', // 添加重定向属性
    meta: {
      noHeader: true, // 标记不需要头部组件
    },
    children: [
      {
        path: '', // 默认子路由
        redirect: 'personalData'
      },
      {
        path: 'accountSettings',
        component: () => import(/* webpackChunkName: "accountSettings" */ '../views/personInfo/components/accountSettings.vue'),

      },
      {
        path: 'Cooperate',
        component: () => import(/* webpackChunkName: "Cooperate" */ '../views/personInfo/components/Cooperate.vue')
      },
      {
        path: 'leaseEquip',
        component: () => import(/* webpackChunkName: "leaseEquip" */ '../views/personInfo/components/leaseEquip.vue')
      },
      {
        path: 'myArticles',
        component: () => import(/* webpackChunkName: "myArticles" */ '../views/personInfo/components/myArticles.vue')
      },
      {
        path: 'myTeam',
        component: () => import(/* webpackChunkName: "myTeam" */ '../views/personInfo/components/myTeam.vue')
      },
      {
        path: 'personalData',
        component: () => import(/* webpackChunkName: "personalData" */ '../views/personInfo/components/personalData.vue')
      },
      {
        path: 'personalShow',
        component: () => import(/* webpackChunkName: "personalShow" */ '../views/personInfo/components/personalShow.vue')
      },
      {
        path: 'authentication',
        component: () => import(/* webpackChunkName: "authentication" */ '../views/personInfo/components/authentication.vue')
      },
    ]
  },
  {
    path: '/gdmec',
    component: () => import(/* webpackChunkName: "gdmec" */ '../views/Team/gdmec.vue'),
    // children:[
    //   {
    //     path:'gdhome',
    //     component:() => import(/* webpackChunkName: "gdhome" */ '../views/Team/components/gdhome.vue')
    //   },
    //   {
    //     path:'gdintroduc',
    //     component:() => import(/* webpackChunkName: "gdintroduc" */ '../views/Team/components/gdintroduc.vue')
    //   },
    //   {
    //     path:'gdmember',
    //     component:() => import(/* webpackChunkName: "gdmember" */ '../views/Team/components/gdmember.vue')
    //   },
    //   {
    //     path:'gdimage',
    //     component:() => import(/* webpackChunkName: "gdimage" */ '../views/Team/components/gdimage.vue')
    //   },
    //   {
    //     path:'gdresources',
    //     component:() => import(/* webpackChunkName: "gdresources" */ '../views/Team/components/gdresources.vue')
    //   },
    // ]
  },
  {
    path: '/leaseDetail',
    component: () => import(/* webpackChunkName: "leaseDetail" */ '../views/lease/leaseDetail.vue')
  },
  {
    path: '/leaseEj',
    component: () => import(/* webpackChunkName: "leaseEj" */ '../views/lease/leaseEj.vue')
  },
  {
    path: '/leasePublish',
    component: () => import(/* webpackChunkName: "leasePublish" */ '../views/lease/leasePublish.vue')

  },
  {
    path: '/cooperat',
    component: () => import(/* webpackChunkName: "cooperat" */ '../views/cooperat.vue')
  },
  {
    path: '/personHome',
    component: () => import(/* webpackChunkName: "personHome" */ '../views/personInfo/personHome.vue'),
    redirect: '/personHome/PERarticle', // 添加重定向属性
    children: [
      {
        path: 'PERarticle',
        component: () => import(/* webpackChunkName: "PERarticle" */ '../views/personInfo/components/PERarticle.vue')
      },
      {
        path: 'PERteam',
        component: () => import(/* webpackChunkName: "PERteam" */ '../views/personInfo/components/PERteam.vue')
      },
      {
        path: 'PERlease',
        component: () => import(/* webpackChunkName: "PERlease" */ '../views/personInfo/components/PERlease.vue')
      },
    ],
    meta: {
      noHeader: true, // 标记不需要头部组件
    }
  },
  {
    path: '/register',
    component: () => import(/* webpackChunkName: "register" */ '../views/register.vue'),
    meta: {
      noTopBar: true, // 标记不需要顶部直通车组件
      noHeader: true, // 标记不需要头部组件
      noFooter: true, // 标记不需要页尾组件
      noLoginModal: true, // 标记不需要登录模块组件
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    meta: {
      noHeader: true, // 标记不需要头部组件
      noFooter: true, // 标记不需要页尾组件
    }
  },

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})


// 添加路由守卫代码在这里
// router.beforeEach((to, from, next) => {
//   const existingRoute = routes.find(route => route.path === to.path || (route.children && route.children.find(child => child.path === to.path)));
//   if  (existingRoute) {
//     next();
//   } else {
//     next('/404');
//   }
// });


//
// 把这段代码直接粘贴到router/index.js中的Vue.use(VueRouter)之前
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
};
export default router
