import {getMoneyBalance} from "@/api/myMoney/balance"
export default{
    namespaced:true,
    state: {
        balance:0,  //余额
    },
    mutations: {
        setBalance(state,newBalance){
            state.balance = newBalance
        }
    },
    actions: {
       async fetchBalance({commit}){
        const {data} = await getMoneyBalance()
        commit("setBalance",data.balance)
        // console.log(data.balance,66666);
        }
    },
}