export default{
    namespaced:true,
    state: {
        //输入框text
        searchInput: '', // 初始化为空字符串
    },
    mutations: {
        updateSearchInput(state, value) {
            state.searchInput = value;
          },
    },
    actions: {
    },
}