import request from '../request/request'
import { getRefreshToken } from '@/utils/auth'
// 请求的登录用户信息
export const UserProfilesAPI = () => request.get("/member/auth/get-permission-info")

//刷新令牌接口

// export const  getRefreshtoken= (params) => request.post("/member/auth/refresh-token",params)
// console.log(refreshToken,89889);
export const  refreshToken= () => request.post('/member/auth/refresh-token?refreshToken=' + getRefreshToken())

// 刷新访问令牌
// export function refreshToken() {
//     return service({
//       url: '/member/auth/refresh-token?refreshToken=' + getRefreshToken(),
//       method: 'post'
//     })
//   }