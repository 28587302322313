//验证手机号是否符合规则
export const validatePhoneNumber = value =>{
    let reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
    return reg.test(value)
}

//验证邮箱号是否符合规则
export const validateEmailNumber = value =>{
    let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
    return reg.test(value)
    
}

//登录密码验证规则
export const validatePassword = value =>{
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/
    // let reg = /^[0-9]{6,18}$/
    return reg.test(value)
}

//登录账号验证规则
export const validateNumber = value =>{
    let reg = /^[^\u4e00-\u9fa5]{6,12}$/
    return reg.test(value)
}

//用户昵称的验证规则
export const validateUsername = value =>{
    let reg = /^[\u4e00-\u9fa5a-zA-Z0-9]{2,6}$/
    return reg.test(value)
}