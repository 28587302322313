export default{
    namespaced:true,
    state: {
        //用来展示Toast的显示与隐藏

        //提示类型 成功（success） 警告(waring)  失败(fail)
        isShowToast:false,
        msg:"",
        type:""
    },
    mutations: {
        //修改isShowLoginModal的值
        chanIsShowToast(state,payload){
            state.isShowToast = payload.isShow,
            state.msg = payload.msg,
            state.type = payload.type
        }
    },
    actions: {
        asyncChanIsShowToast(context,payload){
            //先展示
            context.commit("chanIsShowToast",{
                isShow:true,
                msg:payload.msg,
                type:payload.type

            })

            // 关闭
            setTimeout(()=>{
                context.commit("chanIsShowToast",{
                  isShow:false,
                  msg:payload.msg,
                  type:payload.type
                })
              },1500)
        }
    },
}