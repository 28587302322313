export default{
    namespaced:true,
    state: {
        //登录状态值
        isLogined:localStorage.getItem("accessToken")?true : false
    },
    mutations: {
        //修改isShowLoginModal的值
        chanIsLogined(state,payload){
            state.isLogined = payload
        }
    },
    actions: {
    },
}