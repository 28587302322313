import { UserProfilesAPI } from "@/api/login"
export default{
    namespaced:true,
    state: {
        //用户信息
        pcMemberVO:{
            avatar:require("../../assets/img/avatar.png"),
            id:"--",
            nickname:"游客"
        }

    },
    mutations: {
        //修改isShowLoginModal的值
       chanPcMemberVO(state,payload){
            state.pcMemberVO = payload.pcMemberVO
        },
        initpcMemberVO(state){
            state.pcMemberVO={
                avatar:require("../../assets/img/avatar.png"),
                id:"--",
                nickname:"游客"
            }
        }
    },
    actions: {
        async asyncChanPcMemberVO({commit}){
            let userInfoRes = await UserProfilesAPI();
            commit("chanPcMemberVO",userInfoRes.data)
        }
    },
}