import request from '../request/request'

// 创建团队
export const createTeamPublish = (params) => request.post("/lxkj/team/create",params)

//团队详情
export const getTeamDetails = (id) => request.get(`/lxkj/team/get?id=${id}`)

//获得团队简略分页
export const postTeamPages = (params) => request.post("/lxkj/team/page",params)

//个人中心  团队列表
export const personTeamList = (params) => request.post("/lxkj/team/page",params)