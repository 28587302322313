<template>
 <div class="IGcontent">
        <div class="IGbox" v-for="item in showAll ?  everyList : everyList.slice(0, dataCount)" :key="item.id" @click="togdmec(item.id)">
            <div class="IGimg">
                <img :src="item.avatar" alt=""/>
            </div>
            <div class="p">{{item.name}}</div>
            <div class="name">创建人：{{ item.pcMemberVO.nickname }} </div>
        </div>
   
 </div>
</template>

<script>
 export default {
   name: 'IGcontent',
   props: ['everyList','showAll','dataCount'],
   components: {

   },
   data () {
     return {

     }
   },
   created(){
    console.log(this.everyList,99922);
   },
   methods: {
    togdmec(id){
      this.$router.push({
      path: '/gdmec',
      query: {
        itemId: id,
      }
    })
    },
   },
   mounted() {

   },
 }
</script>

<style lang='scss' scoped>
 .IGcontent{
    width: 100%;
    // height: 210px;
    // padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 61px;
    .IGbox{
        width: 190px;
        height: 190px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 6px #909090;
        cursor: pointer;
        .IGimg{
            width: 181px;
            height: 125px;
            background-color: #fff;
            margin: 0 auto;
            border-radius: 10px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                border: none;
                
            }
        }
        .p{
            font-size: 15px;
            font-weight: bold;
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 5px 0 0 7px;
        }
        .name{
            max-height: 40px;
            overflow: hidden;
            line-height: 21px;
            font-weight: normal;
            font-size: 12px;
            padding: 15px 0 0 7px;
        }
    }

    
 }
</style>