<template>
  <div id="app">
    <!-- 顶部直通车 -->
    <TopBar :key="topBarKeyValue"></TopBar>
    <!-- 头部 -->
    <Header v-if="!$route.meta.noHeader"></Header>
    <router-view />
    
    <!-- 页尾 -->
    <Footer v-if="!$route.meta.noFooter"></Footer>

    <!-- 登录模块组件 -->
    <Login v-show="!$route.meta.noLoginModal && isShowLoginModal"></Login>

    <!-- 提示框组件 -->
    <transition name="fade">
      <Toast v-show="!$route.meta.noToast && isShowToast"></Toast>
    </transition>

  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Login from '@/components/Login'
import Toast from '@/components/Toast'
import { mapState } from 'vuex'
export default {
  components: {
    TopBar,
    Header,
    Footer,
    Login,
    Toast
  },
  data() {
    return {
      topBarKeyValue: 1,
      isShowHeader: true
    }
  },
  watch: {


  },
  computed: {
    ...mapState({
      isShowLoginModal: state => state.showLoginModal.isShowLoginModal,
      isShowToast: state => state.ShowToast.isShowToast
    })
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

// 进场的初始时候状态
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

//进场过渡属性
.fade-enter-active,
.fade-leave-active {
  transition: all .5s linear;
}

//进场结束的状态 == 离场的初始状态
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
</style>
