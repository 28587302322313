export default{
    namespaced:true,
    state: {
        //登录模块窗口的显示与隐藏
        isShowLoginModal:false
    },
    mutations: {
        //修改isShowLoginModal的值
        chanIsShowLoginModal(state,payload){
            state.isShowLoginModal = payload
        }
    },
    actions: {
    },
}