<template>
<!-- 提示框封装组件 -->
 <div class="toast">
    <!-- 看type值 -->
    <i :class="type == 'success'?'iconfont icon-toast_chenggong':(type == 'warning'?'iconfont icon-toast-jinggao':'iconfont icon-toast-shibai_huaban') "></i>
    <span>{{msg}}</span>
 </div>
</template>

<script>
import {mapState} from 'vuex'
 export default {
   name: 'Toast',
   props: {
   },
   components: {

   },
   data () {
     return {

     }
   },
   computed:{
    ...mapState({
        msg:state=>state.ShowToast.msg,
        type:state=>state.ShowToast.type,
    })
   },
   methods: {

   },
   mounted() {

   },
 }
</script>

<style lang='scss' scoped>
    .toast{
        z-index: 10000;
        background-color: #fff;
        border-radius: 5px;
        // box-shadow: 3px 3px 3px 3px rgba(255,255,0,0.5);
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
        position: fixed;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        padding: 10px 20px;
        i{
            margin-right: 8px;
        }
        .icon-toast_chenggong{
            color: green;
        }
        .icon-toast-jinggao{
            color: orange;
        }
        .icon-toast-shibai_huaban{
            color: red;
        }
    }
</style>