import request from '../request/request'

// 请求租赁设备类型
export const getLeaseTypes = () => request.get("/lxkj/device-type/get-category")

// 获取设备类型信息
export const devicesTypeInfo = (params) => request.post("/lxkj/device/search",params)

//创建租赁发布
export const createLeasePublish = (params) => request.post("/lxkj/device/save",params)


//租赁设备详情
export const getLeaseServeDetails = (id) => request.get(`/lxkj/device/get-details?id=${id}`)

//获得分类属性
export const getTypesAttribute = (id) => request.get(`/lxkj/device-type/get-attribute?id=${id}`)

//获取设备列表信息
export const getleaselistInfo = (params) => request.post("/lxkj/device/get-devices-list",params)