<template>
 <div class="title">
    <div class="l">
        <img src="../../assets/img/tuandui.png" alt="">
        <h2>{{ title }}</h2>
    </div>
    <div class="r" @click="handleClick">
        更多
    </div>
 </div>
</template>

<script>
 export default {
   name: 'JfTitle',
   props: ['title','imgUrl','path'],
   components: {

   },
   data () {
     return {

     }
   },
   methods: {
    handleClick() {
      this.$router.push(this.path); // 使用传递的路径进行跳转
    },
   },
   mounted() {

   },
 }
</script>

<style lang='scss' scoped>
    .title{
        width: 100%;
        height: 36px;
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        .l{
            display: flex;
            align-items: center;
            img{
                width: 60px;
            }
            h2{
                font-size: 28px;
                color: #2b3e93;
                background-position: left center;
                background-repeat: no-repeat;
                font-weight: bold;
                letter-spacing: 1px;
            }
        }
        .r{
            width: 70px;
            height: 26px;
            text-align: center;
            color: #4962c9;
            background-color: #d8dff7;
            line-height: 26px;
            cursor: pointer;
            
        }
        .r:hover{
            background-color: #b1c1f5;
            color: #fff;
        }
    }
</style>