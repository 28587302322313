export default {
    namespaced: true,
    state: {
        //用户id
        userId: '',

    },
    mutations: {
        updataUser(state, value) {
            state.userId = value;
        },
    },
    actions: {
    },
}