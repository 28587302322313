<template>
  <div class="top-bar">
    <div class="w top-bar-wrap" ref="navbar">
      <div class="l" @click="$router.push('/Home')">欢迎来到蓝希科技</div>
      <div class="r">
        <ul>
          <li style="display: flex;align-items: center;" @click="goPersonCt">
            <img class="avatar" width="26" height="26"
              :src="pcMemberVO.avatar ? pcMemberVO.avatar : require('@/assets/img/ARavatar.png')" alt="" />
            用户名：{{ pcMemberVO.nickname }}
          </li>
          <li>我的积分：0</li>
          <li>获取积分</li>
          <!-- <li>我的空间</li> -->
          <!-- <li class="login-btn" v-show="!isLogined" @click="toLogin" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">登录</li> -->
          <li class="login-btn" v-show="!isLogined" @click="toLogin" style="display: flex;justify-content: center;align-items: center;">登录</li>
          <li class="change-btn aaa" v-show="isLogined" @mouseover="showDropdown = true"
            @mouseleave="showDropdown = false">
            <div style="display: flex;justify-content: center;align-items: center;">
              <img width="18" src="../assets/img/success.png" alt="">
              <span>成功</span>
            </div>

            <!-- <b>{{cartTotal}}</b> -->
            <div class="dropDown" ref="submenu" v-show="showDropdown" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">
              <ul>
                <li><a href="/personCt">个人中心</a></li>
                <li><a href="/myMoney">我的钱包</a></li>
                <li @mouseover="showPublish = true" @mouseleave="showPublish = false">发布信息</li>
                <li @click="goOutLogin">退出登录</li>
              </ul>
              <div class="publish" v-show="showPublish" @mouseover="showMethods" @mouseleave="leaveMethods">
                <ul>
                  <li><a href="/arPublish">文章咨询</a></li>
                  <li><a href="/TeamPublish">团队发布</a></li>
                  <li><a href="/ZlinPublish">租赁发布</a></li>
                  <li><a href="/CooperatPublic">合作平台</a></li>
                  <!-- <li><a href="">合作展示</a></li> -->
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 下拉条 -->

    <!-- <div class="publish" v-show="showPublish" @mouseover="showMethods" @mouseleave="leaveMethods">
      <ul>
        <li><a href="/arPublish">发布资讯</a></li>
        <li><a href="/TeamPublish">团队发布</a></li>
        <li><a href="/ZlinPublish">租赁发布</a></li>
        <li><a href="/PersonPublish">个人展示</a></li>
        <li><a href="">合作展示</a></li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import LCenter from "../components/Center.vue"
import { mapMutations, mapState, mapActions } from "vuex"
export default {
  name: 'TopBar',
  props: {
  },
  components: {
    LCenter
  },
  data() {
    return {
      cartTotal: 10,
      showDropdown: false,
      showPublish: false
    }
  },
  computed: {
    ...mapState({
      isLogined: state => state.loginStatus.isLogined,
      pcMemberVO: state => state.userInfo.pcMemberVO
    })
  },
  watch: {
    "$route.path": {
      handler(newVal, oldVal) {
        // console.log("什么时候执行？？？",newVal,oldVal);
        let mytoken = localStorage.getItem("ACCESS_TOKEN")
        // if(mytoken){
        //   this.chanIsLogined(true)
        // }else{
        //   this.chanIsLogined(false)
        // }
        this.chanIsLogined(Boolean(mytoken))
        if (mytoken) {
          //有登陆的情况，要发送用户信息请求
          this.asyncChanPcMemberVO()
        } else {
          this.initpcMemberVO()
        }
      }
    }
  },

  //路由切换组建的重载，效果就是路由切换引起的created的执行
  created() {
    // console.log("zhixing");
    let mytoken = localStorage.getItem("ACCESS_TOKEN")
    // if(mytoken){
    //   this.chanIsLogined(true)
    // }else{
    //   this.chanIsLogined(false)
    // }
    this.chanIsLogined(Boolean(mytoken))
    if (mytoken) {
      //有登陆的情况，要发送用户信息请求
      this.asyncChanPcMemberVO()
    } else {
      this.initpcMemberVO()
    }
  },
  mounted() {

  },
  methods: {
    ...mapMutations({
      chanIsShowLoginModal: "showLoginModal/chanIsShowLoginModal",
      chanIsShowToast: "ShowToast/chanIsShowToast",
      chanIsLogined: "loginStatus/chanIsLogined",
      initpcMemberVO: "userInfo/initpcMemberVO"
    }),
    ...mapActions({
      asyncChanIsShowToast: "ShowToast/asyncChanIsShowToast",
      asyncChanPcMemberVO: "userInfo/asyncChanPcMemberVO"
    }),
    goPersonCt(){
      this.$router.push("/personCt")
    },

    aaa(){
      this.showDropdown = false
    },
    showXL(event) {
      this.showDropdown = true
    },

    leaveXL() {
      this.showDropdown = false
    },


    //二级下拉条逻辑
    showMethods() {
      this.showPublish = true
      this.showDropdown = true
    },
    leaveMethods() {
      this.showPublish = false
      this.showDropdown = false
    },
    toLogin() {
      this.chanIsShowLoginModal(true)
    },
    // 退出登录
    goOutLogin() {
      this.$router.push('/home')
      localStorage.removeItem('ACCESS_TOKEN');
      localStorage.removeItem('refreshToken');
      // localStorage.removeItem('expiresTime');
      // localStorage.removeItem('currentTime');
      window.location.reload();

      this.asyncChanIsShowToast({
        msg: "成功退出!",
        type: "success"
      })

    },
    toShowToast() {
      //提示类型 成功（success） 警告(waring)  失败(fail)
      this.asyncChanIsShowToast({
        msg: "登陆成功!",
        type: "success"
      })
    },
  },
}
</script>

<style lang='scss' scoped>
@import "../assets/css/global.scss";

.top-bar {
  // position: relative;
  height: 40px;
  background-color: #242b39;
  box-shadow: 0 4px 8px 0 #ddd;
  color: #fffefe;
  line-height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .top-bar-wrap {
    height: 40px;
    display: flex;
    justify-content: space-between;
    //字体样式
    font-size: 14px;
    font-family: SourceHanSanSC;
    font-weight: 300;
    color: #fffefe;

    .l {
      cursor: pointer;
    }

    .r {
      &>ul {
        display: flex;

        &>li {
          cursor: pointer;
          // display: flex;
          // align-items: center;
          margin-left: 20px;

          .avatar {
            border-radius: 50%;
            margin-right: 10px;
          }

          &.login-btn {
            width: 124px;
            height: 40px;
            background-color: $base-color;
            justify-content: center;
          }
        }

      }
    }
  }

}

.change-btn {
  width: 124px;
  height: 40px;
  background-color: $base-color;
  // display: flex;
  // justify-content: center;
  position: relative;

  img {
    margin-right: 5px;
  }

  b {
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
  }
}

.aaa {
  position: relative;
}


.bbb {
  position: absolute;
  width: 124px;
  height: 200px;
  background-color: #0a328e;

}

.dropDown {
  position: absolute;
  // top: 40px;
  // left: 1428px;
  width: 124px;
  height: 200px;
  background-color: #0a328e;
  // position: fixed;

  // right: 0;
  // z-index: 9999;

  ul {
    li {
      text-align: center;
      font-size: 14px;
      // border-bottom: 1px solid #ccc;
      cursor: pointer;
      color: #fff;

      a {
        color: #fff;
        font-size: 14px;
      }

      a:hover {
        color: orange;
      }

    }

    li:hover {
      color: orange;
    }

  }
}

.publish {
  position: absolute;
  width: 124px;
  height: 200px;
  background-color: #0a328e;
  top: 0px;
  left: 124px;
  // left:30%;
  z-index: 9999;

  ul {
    li {
      text-align: center;
      font-size: 14px;
      // border-bottom: 1px solid #ccc;
      cursor: pointer;

      a {
        color: #fff;
        font-size: 14px;
      }

      a:hover {
        color: orange;
      }

    }

  }
}
</style>