import Vue from 'vue'
import Vuex from 'vuex'
import showLoginModal from './showLoginModal'
import loginStatus from './loginStatus'
import ShowToast from "./ShowToast"
import userInfo from "./userInfo"
import myMoney from "./myMoney"
import searchText from "./searchText"
import personCneter from "./personCneter"
Vue.use(Vuex)

export default new Vuex.Store({
  
  modules: {
    showLoginModal,
    loginStatus,
    ShowToast,
    userInfo,
    myMoney,
    searchText,
    personCneter
  }
})
