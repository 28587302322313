<template>
  <div class="footer-b">
    <div class="f_left">
      <h2>
        <a href="#">蓝希</a>
      </h2>
    </div>
    <div class="f_w">
      <ul>
        <li>公司名称：山西省蓝希科技有限公司</li>
        <li>地址：山西省长治市潞州区大辛庄街道捉马东大街盛德世家A座703</li>
        <li>邮箱：lanxikj001@lanxikj.com</li>
        <li><a href="https://beian.miit.gov.cn">晋ICP备2023024380号 </a></li>
      </ul>
    </div>
    <div class="f_right">
      <p>欢迎咨询</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      // 将延时加载的标志位设置为 true，用于控制 footer 的显示
      this.$emit('showFooter');
    }, 1000); // 设置延时时间，单位为毫秒，这里设置为 1000 毫秒，即 1 秒钟
  },
};
</script>
 
<style lang = "scss" scoped>
.footer-b {
  height: 240px;
  background: #282828;
  display: flex;
  .f_left{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
      h2{
          font-size: 80px;
          font-family: "宋体", "SimSun", sans-serif;
          font-weight: 800;
        a{
          color: #fff;
        }
        }
  }
  .f_w{
    width: 1000px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
        ul {
          li {
            height: 20px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #fff;
            padding-top: 3px;
            a{
              color: #fff;
            }
          }
        }
  }
  .f_right{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p{
      font-size: 25px;
      color: #fff;
    }
  }
}
</style>