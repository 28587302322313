<template>
 <div class="name" :style="sty">
    111
 </div>
</template>

<script>
 export default {
   name: 'Center',
   props: ['x','y'],
   components: {

   },
   data () {
     return {
        sty:{
            top:(this.y+20)+'px',
            left:(this.y+30)+'px'
        }
     }
   },
   watch:{
        x:function(val){
            this.sty.left=(val+500)+'px'
        },
        y:function(val){
            this.sty.top=(val+30)+'px'
        }
    },
   methods: {

   },

   mounted() {

   },
 }
</script>

<style lang='scss' scoped>
    .name{
        width: 50px;
        height: 50px;
        background-color: aqua;
    }
</style>