<template>
  <div class="bgc">
    <div class="header w">
      <h1>
        <img width="220px" src="../assets/img/lanxi.png" alt="">
      </h1>
      <div class="nav">
        <ul>
          <li @click="$router.push('/home')" :class="$route.path === '/home' ? 'active' : ''">首页</li>
          <li @click="$router.push('/person')" :class="$route.path === '/person' ? 'active' : ''">文章咨询</li>
          <!-- <li @click="$router.push('/informat')" :class="$route.path==='/informat'?'active':''">全部资讯</li> -->
          <li @click="$router.push('/lease')" :class="$route.path === '/lease' ? 'active' : ''">租赁设备</li>
          <li @click="$router.push('/team')" :class="$route.path === '/team' ? 'active' : ''">团队信息</li>
          <!-- <li class="cooperat" @click="$router.push('/cooperat')" :class="$route.path === '/cooperat' ? 'active' : ''"
            @mouseover="showDropdown = true" @mouseleave="showDropdown = false">合作平台</li> -->
        </ul>
      </div>
      <div class="search">
        <button @click="search" class="btnSearch"><i class="el-icon-search"></i>搜索</button>
      </div>
    </div>
    <!-- 下拉条 -->
    <!-- <div class="dropDown" v-show="showDropdown" @mouseover="showDropdown = true" @mouseleave="showDropdown = false">
      <ul>
        <li>退出登录</li>
      </ul>
   </div> -->
  </div>

</template>

<script>
import router from '@/router';
import { mapMutations } from 'vuex';
export default {
  name: 'TopBar',
  props: {
  },
  components: {

  },
  data() {
    return {
      searchInput: "",
      showDropdown: false  //下拉条
    }
  },
  methods: {
    ...mapMutations({
            updateSearchInput: "searchText/updateSearchInput"
        }),
    //搜索
    search() {
      this.$router.push('/searchIndex');
    }
  },
  mounted() {

  },
}
</script>

<style lang='scss' scoped>
.bgc {
  .header {
    margin-top: 40px;
    height: 118px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav ul {
      width: 550px;
      display: flex;
      justify-content: space-between;

      li {
        cursor: pointer;
      }

      .cooperat {
        position: relative;
      }

      .active {
        color: #0a328e;
        font-weight: 700;
      }
    }

    .search {
      display: flex;

      // input {
      //   width: 214px;
      //   height: 38px;
      //   border: 1px solid #DEDEDE;
      //   border-radius: 20px 0px 0px 20px;
      //   text-indent: 12px;
      // }

      .btnSearch {
        width: 180px;
        height: 40px;
        border: 1px solid #1e5fc2;
        background-color: #fff;
        border-radius: 20px 20px 20px 20px;
        color: #858388;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .dropDown {
    position: absolute;
    width: 100px;
    height: 200px;
    background-color: #fff;
    // position: fixed;
    top: 110px;
    left: 1131px;
    // right: 0;
    z-index: 1;

    ul {
      li {
        text-align: center;
        font-size: 14px;
        // border-bottom: 1px solid #ccc;
        cursor: pointer;

        a {
          color: #fff;
          font-size: 14px;
        }

        a:hover {
          color: orange;
        }

      }

    }
  }
}
</style>