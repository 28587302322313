import "reset-css"  //样式清除
import "@/assets/css/global.scss"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SlideVerify from 'vue-monoplasty-slide-verify';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment';


Vue.filter('dateFormat', (str, partten = "YYYY-MM-DD HH:mm:ss") => {
    return moment(str).format(partten);
});
Vue.filter('dateFormat1', (str, partten = "YYYY-MM-DD") => {
  return moment(str).format(partten);
})

Vue.use(ElementUI);


Vue.use(SlideVerify);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
