<template>
    <div class="ContentBox">
        <div class="l_1">
            <!-- 图片 -->
            <div class="lunbo">
                <a href="http://ml-summit.org" target="_blank">
                    <img width="100%" height="100%" src="@/assets/img/hotNews.png" alt="">
                </a>
            </div>
            <!-- 标题 -->
            <div class="l_b">
                <div class="b_title">
                    <a href="http://ml-summit.org" target="_blank"
                        style="overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;color: black;">全球机器学习大会上海站重磅官宣，首批演讲嘉宾和12大议题揭晓！</a>
                </div>
            </div>
        </div>
        <!-- 循环 -->
        <!-- <div class="l_2" v-for="(item,index) in everyList" :key="index"> -->
        <div class="l_2" v-for="(item) in showAll ? everyList : everyList.slice(0, dataCount)" :key="item.id"
            @click="goToDetail(item.id, item.pcMemberVO.nickname)">
            <div class="title_box">
                <p>{{ item.title }}</p>

            </div>
            <div class="img_box">
                <img :src="item.images" alt="">
            </div>
            <div class="img_txt" style="text-align: center;">
                <p>{{ title }}</p>
            </div>
            <div class="l_txt">
                <p v-html="item.displayContent"
                    style="overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;">
                </p>
                <!-- <p style="padding-top: 3px;">第十届陕西省滑雪大会举行</p> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContentBox',
    props: ['everyList', 'showAll', 'dataCount', 'title'],
    //    props:{
    //     everyList: Array,
    //     showAll: Boolean,
    //     dataCount:Number
    //    },
    components: {

    },
    data() {
        return {
            newArray1: [],
            newArray2: []
        }
    },
    created() {
        console.log(this.everyList, 666666);
    },
    mounted() {

    },
    methods: {
        goToDetail(id, name) {
            this.$router.push({
                path: '/txtdetail',
                query: {
                    itemId: id,
                    name: name
                }
            })
        }
    },

}
</script>

<style lang='scss' scoped>
.ContentBox {
    height: 390px;
    // background-color: rgb(255,255,255,.5);
    display: flex;
    justify-content: flex-start;
    gap: 20px;

    .l_1 {
        width: 430px;
        height: 100%;

        .lunbo {
            height: 265px;
        }

        .l_b {
            height: 105px;
            background-color: #fff;
            margin-top: 20px;

            .b_title {
                width: 396px;
                height: 100%;
                background-color: #fff;
                // color: #2c3e50;
                margin: auto;
                text-align: center;
                font-size: 20px;
                line-height: 105px;
            }
        }
    }

    .l_2 {
        width: 236px;
        background-color: #fff;

        .title_box {
            width: 100%;
            padding: 15px 0 15px 10px;
            box-sizing: border-box;
            p {
                color: #2b3e93;
                font-size: 20px;
                height: 20px;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        .img_box {
            height: 125px;
            padding: 0 10px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }
        }

        .img_txt {
            height: 57px;
            border-bottom: 1px solid #dbdbdb;

            p {
                font-size: 14px;
                font-family: "微软雅黑", "Microsoft YaHei", "Microsoft YaHei UI", Arial, SimSun, sans-serif;
                // padding: 5px 0 0 15px;
                line-height: 57px;
            }
        }

        .l_txt {

            // height: 100%;
            p {
                font-size: 14px;
                font-family: "微软雅黑", "Microsoft YaHei", "Microsoft YaHei UI", Arial, SimSun, sans-serif;
                padding: 15px 0 3px 15px;
                line-height: 26px;
            }
        }
    }

    .l_3 {
        width: 236px;
        background-color: #fff;
    }

    .l_4 {
        width: 236px;
        background-color: #fff;
    }
}
</style>