import request from '../request/request'
//get请求
// export function geterTabsList(params) {
//     return request({
//       url: '/product/type/list',
//       params
//     })
//   }
//post请求
// export function getesGoodsList(data) {
//     return request({
//       url: '/product/article/page',
//       data,
//       method: 'post'1
//     })
//
//发送邮箱验证码请求
export const EmailLoginCode = (params) => request.post("/member/auth/send-mail-code",params)

//账号+密码登录
export const PasswordLogin = (params) => request.post("/member/auth/login",params)

//账号注册
export const NumberRegister = (params) => request.post("/member/auth/register-sms",params)

//图片验证码
export const getImgCode = () => request.get("/system/captcha/captchaImage")

//短信验证码
export const getMessageCode = (params) => request.post("/member/auth/send-sms-code",params)