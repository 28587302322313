<template>
  <div class="login-model">
    <div class="mask" @click="closeLogin"></div>
    <div class="login-box">
      <img class="close" @click="closeLogin" width="22" src="../assets/img/close.png" alt="" />
      <ul class="title">
        <!-- <li :class="isShowForm ? 'active' : ''" @click="isShowForm = true">
          邮箱登录
        </li>
        <li style="margin: 0 10px; cursor: auto">|</li> -->
        <!-- <li :class="!isShowForm ? 'active' : ''" @click="toShowWeixin">
          微信扫码登录
        </li> -->
        <!-- <li :class="!isShowForm ? 'active' : ''" @click="toShowPsw">
          账号密码登录
        </li> -->
        <!-- <li class="active" @click="toShowPsw">
          账号密码登录
        </li> -->
        <li class="active">
          账号密码登录
        </li>
      </ul>
      <div class="body">
        <!-- --------------------------------------------邮箱登录------------------------------------------------------------- -->
        <!-- <div class="form" v-show="isShowForm">
          <div class="input-group uname">
            <input ref="phone" type="text" placeholder="请输入邮箱" v-model="phoneNum"/>
          </div>
          滑块验证码
          <div class="input-group mb20">
            <slide-verify :l="42" :r="20" :w="360" :h="140" @success="onSuccess" 
                @fail="onFail" @refresh="onRefresh" :style="{ width: '100%' }" 
                class="slide-box" ref="slideBlock" :slider-text="msg">
            </slide-verify>
          </div>
          
          <div class="input-group code mb20">
            <input type="text" placeholder="请输入短信验证码" v-model="SMScode"/>
            <div class="btn" :class="isShowCount?'has-count':''">
              <span v-show="!isShowCount" @click="getSMScode">获取验证码</span>
              <span v-show="isShowCount">{{count}}s</span>
            </div>
          </div>
          <div class="btn" @click="submitLogin">登录</div>
        </div> -->
        <!-- <div class="wx-qrcode" id="weixin" v-show="!isShowForm">
          微信二维码
        </div> -->
        <!-- ------------------------------------------------账号密码登录--------------------------------------------------- -->
        <div class="Pswform" v-show="isShowForm == true">
          <div class="loginForm">

            <el-form ref="form" :model="form" :rules="rules">
              <!-- 账号 -->
              <el-form-item prop="account">
                <el-input v-model="form.account" placeholder="账号/手机号"></el-input>
              </el-form-item>
              <!-- 密码 -->
              <el-form-item prop="password">
                <el-input type="password" v-model="form.password" placeholder="密码" show-password></el-input>
              </el-form-item>
              <!-- 验证码 -->
              <el-form-item prop="VerificationCode">
                <div style="display: flex;align-items: center;">
                  <el-input prefix-icon="el-icon-mobile-phone" type="text" placeholder="点击图片更换验证码"
                    v-model="form.VerificationCode" class="vertify_code" auto-complete="false"></el-input>
                  <img :src="captchaUrl" class="vertify_img" @click="resetImg" />
                </div>
              </el-form-item>

              <el-form-item>
                <div style="display: flex;justify-content: center;">
                  <!-- <el-button type="primary" class="loginBtn" @click="onSubmit('form')" @keyup.enter="keyDown(e,'form')">登录</el-button> -->
                  <el-button type="primary" class="loginBtn" @click="onSubmit('form')">登录</el-button>
                </div>
                <div class="zcAccount">
                  <span class="textStyle" @click="registerLogin">注册账号</span>
                </div>
              </el-form-item>
            </el-form>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sm2 } from 'sm-crypto'
import { mapMutations, mapActions } from "vuex";
import { validatePhoneNumber, validateEmailNumber, validatePassword, validateNumber } from "@/utils"
import { EmailLoginCode, PasswordLogin, getImgCode } from "@/api/phoneCode"
import { getAccessToken, getRefreshToken, setToken, removeToken } from "@/utils/auth";
import { UserProfilesAPI } from "@/api/login"

export default {
  name: "Login",
  props: {},
  components: {},
  data() {
    return {
      uuid: '',
      captchaUrl: require('@/assets/img/bj11.jpg'),
      isShowForm: true,
      msg: "向右滑动",   //滑块文字
      phoneNum: "1193970568@qq.com",  //用户手机号
      SMScode: "9999",   //验证码
      number: "",  //账号
      password: "",//密码
      isShowCount: false,   //控制倒计时的显示与隐藏
      count: 60,  //倒计时
      form: {
        account: '',
        password: '',
        VerificationCode: ''
      },
      rules: {
        account: [
          { required: true, trigger: 'blur', message: '账号不能为空' },
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空' },
          {
            min: 8, max: 16, message: '密码长度必须在8到16位之间', trigger: 'blur'
          },
          {
            pattern: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!#@*&.])[a-zA-Z\d!#@*&.]{8,16}$/,
            message: '密码必须包含数字、大小写字母和特殊符号',
            trigger: 'blur'
          }
        ],
        VerificationCode: [
          { required: true, trigger: 'blur', message: '请输入4位验证码' },
          { required: true, trigger: 'blur', min: 4, max: 4, message: '验证码错误' }
        ],
      },
    };
  },
  created() {
    this.resetImg()
  },

  methods: {
    ...mapMutations({
      chanIsShowLoginModal: "showLoginModal/chanIsShowLoginModal",
      chanIsLogined: "loginStatus/chanIsLogined"
    }),
    ...mapActions({
      asyncChanIsShowToast: "ShowToast/asyncChanIsShowToast",
      asyncChanPcMemberVO: "userInfo/asyncChanPcMemberVO"
    }),
    //获取图片验证码
    async resetImg() {
      const { data } = await getImgCode()
      this.captchaUrl = 'data:image/png;base64,' + data.imageBytes
      this.uuid = data.uuid
    },
    //账号密码登录  图片验证码的登录
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        // 无论校验是否通过，都执行刷新验证码的操作
        this.resetImg();
        if (valid) {
          //账号密码加密 sm2加密
          const publicKey = "04e53455fc25984b9d2743dc87cd42305da925dc57a50bc781f3c7d4bf552a0ae001e74768789a5a7063774344f801024be3fe3fc84611ac67d57ae4f4a448dd33";//加密使用
          const encrAccount = this.form.account;//例如var enxrText = this.phone;
          const encrPwd = this.form.password;//例如var enxrText = this.phone;
          const cipherMode = 1;
          let encryptedAccount = "04" + sm2.doEncrypt(encrAccount, publicKey, cipherMode) // 加密结果
          let encryptedPassword = "04" + sm2.doEncrypt(encrPwd, publicKey, cipherMode) // 加密结果

          let res = await PasswordLogin({
            username: encryptedAccount,
            password: encryptedPassword,
            captcha: {
              uuid: this.uuid,
              captchaCode: this.form.VerificationCode
            },
          })

          if (res) {
            //登录成功的逻辑
            // 1 提示用户登陆成功
            this.asyncChanIsShowToast({
              msg: "登陆成功！",
              type: "success"
            })
            // 2 关闭登录窗口
            this.closeLogin()
            // 3 保存token的值到localStora中
            setToken(res.data.accessToken, res.data.refreshToken)
            // 4 登陆状态的切换
            this.chanIsLogined(true)
            // 登陆成功之后发送用户信息的请求
            this.asyncChanPcMemberVO()
          }
        }
      });
    },
    //注册
    registerLogin() {
      this.$router.push('/register')
      this.closeLogin()
    },

    toShowPsw() {
      this.isShowForm = false
    },
    toShowWeixin() {
      //切换到展示二维码界面
      this.isShowForm = false
      //微信登录第⼀步：申请微信登录⼆维码
      // let _this=this;
      // new WxLogin({
      // id: "weixin",
      // appid: "wx67cfaf9e3ad31a0d",  //这个appid要填死
      // scope: "snsapi_login",
      // //扫码成功后重定向的接⼝
      // redirect_uri: "https://sc.wolfcode.cn/cms/wechatUsers/shop/PC",
      // // state填写编码后的url
      // state: encodeURIComponent(window.btoa("http://127.0.0.1:8080"+_this.$route.path)),
      // //调⽤样式⽂件
      // href: "data:text/css;base64,Lyogd3hsb2dpbi5jc3MgKi8NCi5pbXBvd2VyQm94IC50aXRsZSwgLmltcG93ZXJCb3ggLmluZm97DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoNCi5pbXBvd2VyQm94IC5xcmNvZGV7DQogIG1hcmdpbi10b3A6IDIwcHg7DQp9",
      // });
    },
    countDown() {
      let timer = setInterval(() => {
        this.count--
        //每一次验证是否到0
        if (this.count === 0) {
          clearInterval(timer);
          this.isShowCount = false;
          this.count = 60
        }
      }, 1000)
    },
    //抽取的方法 1.验证手机号和2.滑块的
    verifyFn() {
      // 1.验证手机号格式是否正确
      if (!validateEmailNumber(this.phoneNum)) {
        this.asyncChanIsShowToast({
          msg: "请输入正确的手机号码!",
          type: "danger"
        })
        //给用户直接聚焦
        this.$refs.phone.focus();
        return false
      }

      //2.滑块验证码验证
      if (this.msg === "再试一次" || this.msg === "向右滑动") {
        this.asyncChanIsShowToast({
          msg: "请滑块滑动到正确位置！",
          type: "danger"
        })
        return false
      }

      return true
    },
    //抽取的方法 1.验证登录账号和2.登陆密码
    verifyFnPsw() {
      // 1.验证手机号格式是否正确
      if (!validateNumber(this.number)) {
        this.asyncChanIsShowToast({
          msg: "请输入正确格式8-12位数字",
          type: "danger"
        })
        //给用户直接聚焦
        this.$refs.phone.focus();
        return false
      }

      //2.密码验证
      if (!validatePassword(this.password)) {
        this.asyncChanIsShowToast({
          msg: "请输入至少八个字符，至少一个大写字母，一个小写字母，一个数字和一个特殊字符",
          type: "danger"
        })
        //给用户直接聚焦
        this.$refs.number.focus();
        return false
      }

      //2.滑块验证码验证
      if (this.msg === "再试一次" || this.msg === "向右滑动") {
        this.asyncChanIsShowToast({
          msg: "请滑块滑动到正确位置！",
          type: "danger"
        })
        return false
      }

      return true
    },

    async getSMScode() {
      // 1. 2.的抽取了
      //点击短信验证码
      if (!this.verifyFn()) {
        return
      }

      //3.发送短信验证码

      // (目前是发送邮箱验证码的接口)
      let res = await EmailLoginCode({
        mail: this.phoneNum.trim(),
        scene: 1
      })

      if (!res) return;
      // 成功执行这里面的代码
      this.asyncChanIsShowToast({
        msg: "短信验证码发送成功",
        type: "success"
      })
      console.log("----", res);
      this.isShowCount = true
      // 倒计时
      this.countDown()




    },
    closeLogin() {
      this.chanIsShowLoginModal(false);
    },
    // 拼图成功
    onSuccess(times) {
      let ms = (times / 1000).toFixed(1);
      this.msg = "登陆成功, 耗时 " + ms + "s";
    },
    // 拼图失败
    onFail() {
      this.onRefresh(); // 重新刷新拼图
    },
    // 拼图刷新
    onRefresh() {
      this.msg = "再试一次";
    },
    //密码登录
    async PswLogin() {
      //点击登录按钮执行代码
      //1 手机号验证
      //2 图片滑块验证
      // 1. 2.的抽取了
      //点击短信验证码
      if (!this.verifyFnPsw()) {
        return
      }
      const sm2 = require('sm-crypto').sm2;
      const publicKey = "04e53455fc25984b9d2743dc87cd42305da925dc57a50bc781f3c7d4bf552a0ae001e74768789a5a7063774344f801024be3fe3fc84611ac67d57ae4f4a448dd33";//加密使用
      const encrText = this.number;//例如var enxrText = this.phone;
      const encrPwd = this.password;//例如var enxrText = this.phone;
      const cipherMode = 1;
      let encryptedUsername = "04" + sm2.doEncrypt(encrText, publicKey, cipherMode) // 加密结果
      let encryptedPassword = "04" + sm2.doEncrypt(encrPwd, publicKey, cipherMode) // 加密结果

      let res = await PasswordLogin({
        // username:this.number,
        // password:this.password
        username: encryptedUsername,
        password: encryptedPassword
      })
      // console.log(res,9999);
      // console.log(res.data.accessToken);
      if (res) {
        //登录成功的逻辑
        // 1 提示用户登陆成功
        this.asyncChanIsShowToast({
          msg: "登陆成功！",
          type: "success"
        })
        // 2 关闭登录窗口
        this.closeLogin()
        // 3 保存token的值到localStora中
        // localStorage.setItem("accessToken",res.data.accessToken)
        // localStorage.setItem("refreshToken",res.data.refreshToken)
        // localStorage.setItem("expiresTime",res.data.expiresTime)
        // localStorage.setItem("AccessTokenKey",res.data.accessToken)
        // localStorage.setItem("RefreshTokenKey",res.data.refreshToken)
        setToken(res.data.accessToken, res.data.refreshToken)
        const currentTime = new Date().getTime();
        // 将当前时间存储到 localStorage 中
        // localStorage.setItem('currentTime', currentTime);


        // 4 登陆状态的切换
        this.chanIsLogined(true)


        // 登陆成功之后发送用户信息的请求
        // let userInfoRes = await UserProfilesAPI()
        // console.log(userInfoRes,"554999");  
        this.asyncChanPcMemberVO()
      }
    },
    //邮箱点击登录
    // async submitLogin(){
    //   //点击登录按钮执行代码
    //   //1 手机号验证
    //   //2 图片滑块验证
    //   // 1. 2.的抽取了
    //   //点击短信验证码
    //   if(!this.verifyFn()){
    //     return
    //   }
    //   //3 验证用户输入的验证码是不是空
    //   if(!this.SMScode.trim()){
    //     this.asyncChanIsShowToast({
    //       msg:"请输入短信验证码",
    //       type:"danger"
    //     })
    //     return
    //   }
    //   //通过验证
    //   //做登录
    //   let res = await PasswordLogin({
    //     username:"123123123",
    //     password:"Buzhidao1!"
    //   })
    //   console.log(res.data.accessToken);
    //   if(res){
    //     //登录成功的逻辑
    //     // 1 提示用户登陆成功
    //      this.asyncChanIsShowToast({
    //       msg:"登陆成功！",
    //       type:"success"
    //     })
    //     // 2 关闭登录窗口
    //     this.closeLogin()
    //     // 3 保存token的值到localStora中
    //     // localStorage.setItem("accessToken",res.data.accessToken)
    //     // localStorage.setItem("refreshToken",res.data.refreshToken)
    //     // 4 登陆状态的切换
    //     this.chanIsLogined(true)


    //     // 登陆成功之后发送用户信息的请求
    //     // let userInfoRes = await UserProfilesAPI()
    //     // console.log(userInfoRes,"554999");  
    //     this.asyncChanPcMemberVO()
    //   } 
    // }
  },
};

</script>

<style lang='scss' scoped>
@import "../assets/css/global.scss";

.login-model {
  z-index: 99;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;

  .mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .login-box {
    width: 555px;
    height: 423px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    .close {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 15px;
    }

    .title {
      display: flex;
      justify-content: center;
      padding: 45px 0 30px;
      color: #ccc;
      font-size: 18px;

      li {
        cursor: pointer;
      }

      .active {
        color: #333;
      }
    }

    .body {
      width: 360px;
      margin: 0 auto;

      // .form {
      //   .uname{
      //     margin-bottom: 5px;
      //   }
      //   .input-group {
      //     display: flex;
      //     input {
      //       height: 48px;
      //       width: 100%;
      //       text-indent: 10px;
      //       border: 1px solid #ccc;
      //     }
      //   }
      //   .code input {
      //     width: 260px;
      //     margin-right: 8px;
      //   }
      //   .code .btn {
      //     flex: 1;
      //   }
      //   .btn {
      //     height: 50px;
      //     line-height: 50px;
      //     background-color: $base-color;
      //     color: #fff;
      //     text-align: center;
      //     cursor: pointer;
      //   }
      //   .has-count{
      //     background-color: #ccc;
      //   }
      // }
      .Pswform {

        // width: 100%;
        //   height: 100%;
        //   background-color: aqua;
        .loginForm {
          width: 100%;
          height: 300px;

          ::v-deep .el-input__inner {
            height: 48px;
            font-size: 15px;
            line-height: 48px;
            // border-color: @;
          }

          .vertify_img {
            width: 90px;
            height: 46px;
            margin-left: 20px;
            border: 1px solid #DCDFE6;
          }

          .vertify_code {
            width: 190px;
          }

          .loginBtn {
            text-align: center;
            width: 100%;
            height: 48px;
            font-size: 15px;
          }

          .zcAccount {
            text-align: center;
            font-size: 12px;
            color: #409EFF;
          }

          .textStyle:hover {
            color: orange;
            cursor: pointer;
          }




        }

        // .uname{
        //   margin-bottom: 15px;
        // }
        // .input-group {
        //   display: flex;
        //   input {
        //     height: 48px;
        //     width: 100%;
        //     text-indent: 10px;
        //     border: 1px solid #ccc;
        //   }
        // }
        // .btn {
        //   height: 50px;
        //   line-height: 50px;
        //   background-color: $base-color;
        //   color: #fff;
        //   text-align: center;
        //   cursor: pointer;
        // }
        // .registerbtn{
        //   height: 50px;
        //   line-height: 50px;
        //   background-color: orange;
        //   color: #fff;
        //   text-align: center;
        //   cursor: pointer;
        //   margin-top: 15px;
        // }
      }
    }
  }
}

//滑块样式
::v-deep .slide-box {
  width: 100%;
  position: relative;
  box-sizing: border-box;

  canvas {
    position: absolute;
    left: 0;
    top: -120px;
    display: none;
    width: 100%;
    box-sizing: border-box;
  }

  .slide-verify-block {
    width: 85px;
    height: 136px;
  }

  .slide-verify-refresh-icon {
    top: -120px;
    display: none;
  }

  &:hover {
    canvas {
      display: block;
    }

    .slide-verify-refresh-icon {
      display: block;
    }
  }
}

.wx-qrcode {
  margin-top: -20px;
  display: flex;
  justify-content: center;
}
</style>