<template>
 <div class="IGtitle">
    <h2>推荐学者</h2>
    <p>Recommended Scholar</p>
 </div>
</template>

<script>
 export default {
   name: 'IGtitle',
   props: {
   },
   components: {

   },
   data () {
     return {

     }
   },
   methods: {

   },
   mounted() {

   },
 }
</script>

<style lang='scss' scoped>
    .IGtitle{
      margin: 20px 0;
        width: 100%;
        height: 40px;
        line-height: 40px;
        h2{
            color: #333;
            font-weight: bold;
            font-size: 16px;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        p{
            color: #999;
            font-size: 13px;
            margin-left: 5px;
            font-family: 'Microsoft YaHei';
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
</style>