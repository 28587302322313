<template>
  <div id="topBanner" class="slide">
    <div v-for="(imgUrl, index) in bannerList" v-show="index === mark" :key="index" class="slideshow">
      <a :href="imgUrl.link" target="_blank">
        <img :src="imgUrl.url" style="width: 100%; height: 450px" :title="imgUrl.title"/>
      </a>
    </div>

    <div class="bar">
      <span v-for="(item, index) in bannerList" :class="{ active: index === mark }" :key="index"
        @click="change(index)"></span>
    </div>
    <div class="LR_btn">
      <div class="l_btn" @click="prev">
        <img src="@/assets/img/preImg.png" alt="">
      </div>
      <div class="r_btn" @click="next">
        <img src="@/assets/img/nextImg.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['bannerList'],
  data() {
    return {
      mark: 0, //比对图片索引的变量
      timer: null, // 添加定时器变量
    };
  },
  methods: {
    prev() {
      this.mark--;
      if (this.mark < 0) {
        this.mark = 3;
      }
      clearInterval(this.timer); // 清除定时器
      this.timer = setInterval(this.autoPlay, 2500); // 重新设置定时器
    },
    next() {
      this.mark++;
      if (this.mark === 4) {
        this.mark = 0;
      }
      clearInterval(this.timer); // 清除定时器
      this.timer = setInterval(this.autoPlay, 2500); // 重新设置定时器
    },
    // 轮播图功能
    autoPlay() {
      this.mark++;
      if (this.mark === 4) {
        this.mark = 0;
      }
    },
    play() {
      this.timer = setInterval(this.autoPlay, 2500);
    },
    change(i) {
      this.mark = i;
      clearInterval(this.timer); // 清除定时器
      this.timer = setInterval(this.autoPlay, 2500); // 重新设置定时器
    },
    // 轮播图功能结束
  },
  created() {
    this.play();
  },
  mounted() { },
};
</script>

<style lang='scss' scoped>
.slide {
  width: 100%;
  height: 450px;
  overflow: hidden;
  position: relative;

  .slideshow {
    width: 100%;
  }

  .bar {
    position: absolute;
    width: 100%;
    bottom: 10px;
    margin: 0 auto;
    z-index: 10;
    text-align: center;

    span {
      width: 116px;
      height: 4px;
      background: rgba(255, 255, 255, 0.5);
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
      /* 添加鼠标指针样式 */
    }

    .active {
      background: #fff !important;
    }
  }
  .LR_btn {
    .l_btn {
      width: 36px;
      height: 70px;
      // padding: 15px 8px 15px 8px;
      position: absolute;
      top: 50%;
      margin-top: -53px;
      background: #000000;
      opacity: 0.5;
      border-radius: 5px;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px;
        height: 40px;
      }
    }

    .l_btn:hover {
      opacity: 1;
    }

    .r_btn {
      width: 36px;
      height: 70px;
      // padding: 15px 8px 15px 8px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -53px;
      background: #000000;
      opacity: 0.5;
      border-radius: 5px;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px;
        height: 40px;
      }
    }

    .r_btn:hover {
      opacity: 1;
    }
  }
}
</style>