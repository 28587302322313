<template>
    <div class="ContentText">
        <div class="ctInfo" v-for="(item, index) in everyList" :key="index" @click="goToDetail(item.id)">
            <h2>{{ item.title }}</h2>
            <div class="newContent">
                <div class="img_content" v-if="item.images">
                    <img width="100%" height="100%" :src="item.images" alt="">
                </div>
                <div class="txt_content">
                    <div class="content">
                        <p v-html="item.displayContent"></p>
                    </div>
                    <div class="avatar">
                        <div class="l">
                            <img :src="item.pcMemberVO.avatar ? item.pcMemberVO.avatar : require('@/assets/img/ARavatar.png')"
                                alt="" />
                            <p>{{ item.pcMemberVO.nickname }}</p>
                        </div>
                        <div class="r">
                            <img src="../assets/img/shijian.png" alt="">
                            <p>{{ item.createTime | dateFormat }}</p>
                            <img src="../assets/img/yueduliang.png" alt="">
                            <p>{{ item.views }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContentText',
    props: ["everyList"],
    components: {

    },
    data() {
        return {

        }
    },
    methods: {
        goToDetail(id) {
            setTimeout(() => {
                this.$router.push({
                    path: '/txtdetail',
                    query: {
                        itemId: id,
                    }
                })
            }, 1);
        }
    },
    mounted() {

    },
}
</script>

<style lang='scss' scoped>
.ContentText {
    width: 100%;
    // height: 600px;
    background-color: #fff;
    margin-top: 20px;
    cursor: pointer;

    .ctInfo {
        width: 1120px;
        height: 150px;
        margin: 0 auto;
        padding: 20px;
        border-bottom: 2px solid #f4f4f4;

        h2 {
            font-size: 18px;
            color: #3d3d3d;
            font-weight: 700;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .newContent {
            width: 100%;
            height: 110px;
            background-color: #fff;
            margin-top: 20px;
            display: flex;

            .img_content {
                width: 150px;
                height: 110px;
                // background-color: yellow;
            }

            .txt_content {
                // width: 930px;
                flex: 1;
                height: 110px;
                margin-left: 20px;

                .content {
                    height: 70px;

                    p {
                        font-size: 14px;
                        font-size: 14px;
                        color: #999999;
                        line-height: 20px;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }

                .avatar {
                    margin-top: 5px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .l {
                        display: flex;
                        align-items: center;
                        z-index: 1;
                        img {
                            width: 26px;
                            height: 26px;
                            border-radius: 50%;
                        }

                        p {
                            line-height: 24px;
                            margin-left: 5px;
                            margin-right: 16px;
                            font-size: 14px;
                            color: #b5b5b5;
                        }
                    }

                    .r {
                        display: flex;
                        align-items: center;

                        img {
                            width: 24px;
                        }

                        p {
                            line-height: 24px;
                            margin-left: 5px;
                            margin-right: 16px;
                            font-size: 14px;
                            color: #b5b5b5;
                        }
                    }
                }
            }
        }
    }
}
</style>